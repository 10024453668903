import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import ButtonWithPopout from "components/Buttons/ButtonWithPopout";
import StandardButton from "components/Buttons/StandardButton";
import UserSharingLinks from "components/Users/UserSharingLinks";

import useWindowSize from "hooks/useWindowSize";

import getHoverQuery from "styles/getHoverQuery";

const buttonStyles = {
  button: {
    borderRadius: 4,

    ...getHoverQuery({
      background: "#f8f8f8",
    }),
  },
  flatHover: {
    boxShadow: "none",
  },
};

const UserProfileSharingLinks = (props) => {
  const {
    user,
    urlParam,
    positionFixed,
    label,
    loading,
    variation,
    fontSize,
    customStyles,
  } = props;
  const { isWindowSizeOrLess } = useWindowSize();

  const customButtonStyles = useMemo(
    () => ({ ...buttonStyles, ...customStyles }),
    [customStyles]
  );

  const renderSharingLinks = useCallback(
    () => <UserSharingLinks {...props} />,
    [props]
  );

  const renderLoading = useCallback(
    () => (
      <div>
        <FontAwesomeIcon icon={faCircleNotch} spin /> Generating...
      </div>
    ),
    []
  );

  const handleToggle = useCallback(
    (toggleMenu) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      toggleMenu();
    },
    []
  );

  const renderButton = useCallback(
    (buttonProps) => (
      <StandardButton
        ref={buttonProps.ref}
        onClick={handleToggle(buttonProps.toggleMenu)}
        variation={variation || "none"}
        label={label}
        customStyles={customButtonStyles}
        fontSize={fontSize}
        flat
      />
    ),
    [handleToggle, variation, label, customButtonStyles, fontSize]
  );

  return (
    <ButtonWithPopout
      id={`share-user-${user.get("id")}${urlParam}`}
      renderButton={renderButton}
      renderContent={loading ? renderLoading : renderSharingLinks}
      positionFixed={positionFixed || isWindowSizeOrLess("small")}
      ariaLabel="Share user profile"
      small
    />
  );
};

UserProfileSharingLinks.propTypes = {
  user: PropTypes.instanceOf(Map),
  urlParam: PropTypes.string,
  positionFixed: PropTypes.bool,
  label: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  variation: PropTypes.string,
  fontSize: PropTypes.string,
  customStyles: PropTypes.object,
};
UserProfileSharingLinks.defaultProps = {
  user: Map({}),
  urlParam: "",
  positionFixed: false,
  loading: false,
  variation: undefined,
  fontSize: undefined,
  customStyles: {},
};

export default memo(UserProfileSharingLinks);
